<template>
    <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
            <v-list>
                <template v-for="(section, i) in sections">
                    <v-subheader :key=i>{{ $t(section.header) }}</v-subheader>
                    <template v-for="(item, j) in section.items">
                        <v-list-tile :key="item.title" :to="`/${$i18n.locale}${item.route}/`">
                            <v-list-tile-content> {{ $t(item.title) }} </v-list-tile-content>
                            <v-list-tile-action v-if="item.value"> {{ item.value }} </v-list-tile-action>
                            <v-list-tile-action v-else-if="item.icon">
                                <v-icon> {{item.icon}} </v-icon>
                            </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="item.title + j"></v-divider>
                    </template>
                </template>
            </v-list>
        </v-flex>
    </v-layout>
</template>

<script>
import store from '@/store'
import { VERSION_KEY } from '@/utils/Updater'
import { SideBtnType } from '@/store'
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'About',
    data() {
        const pageHome = this.$store.getters.pageHome;
        let home = ''
        if (pageHome === HOMETYPES.TRAVELDOCS) {
            home = '/traveldocshome'
        } else if (pageHome === HOMETYPES.WASH) {
            home = '/washhome'
        } else if (pageHome === HOMETYPES.STORE) {
            home = '/storehome'
        }
        return {
            sections: [
                {
                    header: 'about.software-information',
                    items: [
                        { title: 'about.items.version', value: localStorage.getItem(VERSION_KEY), route: home, icon: '' }
                    ]
                },
                {
                    header: 'about.services',
                    items: [
                        { title: 'about.items.terms-of-service', value: '', route: '/about/terms' , icon: 'keyboard_arrow_right'},
                        { title: 'about.items.privacy-policy', value: '', route: '/about/privacy' , icon: 'keyboard_arrow_right'},
                        { title: 'about.items.software-company', value: '', route: '/about/company' , icon: 'keyboard_arrow_right'}
                    ]
                }
            ]
        }
    },
    activated() {
        store.commit('setTitle', 'about.nav-about')
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>

<style scoped>
</style>
